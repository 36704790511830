import React from "react";
import PropTypes from "prop-types";
import "./Style.scss";
import CardFormsFuter from "../CardFormsFuter";

// temp
import { datareadybusiness } from "../../assets/_template/data";

/**
 * 
 * @param {String|Array|Object} className
 *
 */
export default function CardReadyBusiness({ className }) {
    return (
      <div className={("card__recent-studies", className)}>
        {datareadybusiness.map((el, i) => (
        <div className="row pt-sm-3 pt-md-4"key={i} >
          <div className="col-md-6 col-xl-5 offset-xl-1">
            <h2 className="display-3 card__recent-studies_title">{el.title}</h2>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1">
            <p className="fs-xl pb-4 mb-2 mb-lg-3 card__recent-studies_text">{el.text}</p>
            <CardFormsFuter />
          </div>
        </div>
        ))}
      </div>
    );
}

CardReadyBusiness.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};
