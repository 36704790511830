import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollButton from "./Components/ScrollButton/ScrollButton";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <ScrollButton />
    </div>
  );
}

export default App;
