import React from "react";
import PropTypes from "prop-types";
import "./style.css";

// temp
import { datarating } from "../../assets/_template/data";

/**
 * 
 * @param {String|Array|Object} className
 */

export default function CardRating({ className }) {
  return (
      <div className={("rounded-5 py-4 py-md-5 px-lg-5", className)}>
          <div className="row row-cols-2 row-cols-md-4 g-0">
          {datarating.map((el) => (
              <div className="col d-md-flex justify-content-center text-center text-md-start position-relative" key={el.id}>
                  <div className="position-absolute top-50 end-0 translate-middle-y border-end" style={{height: '60px'}}></div>
                  <div className="p-3 px-sm-0 py-sm-4">
                      <div className="rating-number display-5 mb-0">{el.number}</div>
                      <span>{el.content}</span>                      
                  </div>
              </div>
              ))}              
          </div>
      </div>
  );
}

CardRating.propTypes = {
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),    
  };
  
  