import React from "react";
import PropTypes from "prop-types";
//import "./style.css";
import "./Style.scss";

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} link
 * @param {String} imgicon
 * @param {String} title
 * @param {String} text
 * @param {String} imgarrow
 *
 */
export default function CardOurServices({ className, id, link, imgicon, title, text, imgarrow }) {
  return (
      <div className={("card__our-services", className, id)}>        
          <a className="card__our-services_link" href={link}>
              <div className="card__body_our-services card-body pb-0">
                  <div className="image__our-services mb-4">
                      <img src={imgicon} alt="" />                      
                  </div>
                  <div className="content__our-services_wrap">
                      <h3 className="h4 card-title__our-services mt-0">{title.slice(0, 31)}</h3>
                      <p className="card-text desc__our-services_text">{text.slice(0, 140)}</p>
                  </div>
              </div>
              <div className="card-footer border-0 py-3 my-3 mb-sm-4">
                  <div className="btn-icons-arrow__wrap">
                    <img className="btn-icons-arrow" src={imgarrow} alt="" />
                  </div>                                    
              </div>
          </a>
      </div>
  );
}

CardOurServices.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  link: PropTypes.string,
  imgicon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  imgarrow: PropTypes.string,
};
