import { useRef } from "react";

export default function useCardIndustriesSlider([]) {
  const sliderRef = useRef(null);

  const settings = {
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 1380,
        settings: {
         slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
         slidesToShow: 1,
        }
       }
    ]
  };

  return {
    sliderRef,
    settings,
  };
}
