import React from "react";
import PropTypes from "prop-types";
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import "./Style.scss";

// temp
import { datafooter } from "../../assets/_template/data";

/**
 * 
 * @param {String|Array|Object} className
 *
 */
export default function CardFooter({ className }) {
    return (
        <div className={("card__footer", className)}>
            {datafooter.map((el, i) => (
                <div className="row gy-md-5 gy-4 mb-md-5 mb-4 pb-lg-2" key={i}>
                    <div className="col-lg-3">
                        <Navbar.Brand className="navbar-brand d-inline-flex align-items-center mb-lg-4 mb-3" href={el.linkBrand}>
                            <span className="text-primary flex-shrink-0 me-2">
                                <img style={{ width: '35px', height: '32px', objectFit: 'cover' }} src={el.brandImg} alt="" />
                            </span><span className="text-nav">{el.baranddesc}</span>
                        </Navbar.Brand>
                        <p className="mb-4 pb-lg-1 fs-xs text-muted card__footer_brand-text" style={{ maxWidth: '306px' }}>{el.brandtext}</p>
                        <div className="mt-n3 ms-n3">
                            <a className="btn btn-secondary btn-icon btn-sm btn-social rounded-circle mt-3 ms-3" href={el.linksocialIconOne}>
                                <img style={{ width: '15px', height: '15px', objectFit: 'cover' }} src={el.socialIconOne} alt="" />
                            </a>
                            <a className="btn btn-secondary btn-icon btn-sm btn-social rounded-circle mt-3 ms-3" href={el.linksocialIconTwo}>
                                <img style={{ width: '15px', height: '15px', objectFit: 'cover' }} src={el.socialIconTwo} alt="" />
                            </a>
                            <a className="btn btn-secondary btn-icon btn-sm btn-social rounded-circle mt-3 ms-3" href={el.linksocialIconThree}>
                                <img style={{ width: '15px', height: '15px', objectFit: 'cover' }} src={el.socialIconThree} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-8 offset-xl-1 col-lg-9">
                        <div className="row row-cols-sm-4 row-cols-1">
                            <div className="col">
                                <ul className="nav footer-nav_one flex-column mb-0">
                                {el.NavOnetextone ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavOneone}>{el.NavOnetextone}</a></li>
                                ) : null}  
                                {el.NavOnetexttwo ? (  
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavOnetwo}>{el.NavOnetexttwo}</a></li>
                                ) : null}
                                    {el.NavOnetextthree ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavOnethree}>{el.NavOnetextthree}</a></li>
                                ) : null}
                                    {el.NavOnetextfour ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavOnefour}>{el.NavOnetextfour}</a></li>
                                ) : null}
                                    {el.NavOnetextfive ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavOnefive}>{el.NavOnetextfive}</a></li>
                                ) : null}
                                </ul>
                            </div>
                            <div className="col">
                                <ul className="nav footer-nav_two flex-column mb-0">
                                {el.NavTwotextone ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavTwoone}>{el.NavTwotextone}</a></li>
                                ) : null}  
                                {el.NavTwotexttwo ? (  
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavTwotwo}>{el.NavTwotexttwo}</a></li>
                                ) : null}
                                {el.NavTwotextthree ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavTwothree}>{el.NavTwotextthree}</a></li>
                                ) : null}
                                {el.NavTwotextfour ? (  
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavTwofour}>{el.NavTwotextfour}</a></li>
                                ) : null}
                                {el.NavTwotextfive ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavTwofive}>{el.NavTwotextfive}</a></li>
                                ) : null}
                                </ul>
                            </div>
                            <div className="col">
                                <ul className="nav footer-nav_three flex-column mb-0">
                                {el.NavThreetextone ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><NavLink className="nav-link p-0" to={el.linkNavThreeone}>{el.NavThreetextone}</NavLink></li>
                                ) : null}
                                {el.NavThreetexttwo ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavThreetwo}>{el.NavThreetexttwo}</a></li>
                                ) : null}
                                {el.NavThreetextthree ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavThreethree}>{el.NavThreetextthree}</a></li>
                                ) : null}
                                {el.NavThreetextfour ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavThreefour}>{el.NavThreetextfour}</a></li>
                                ) : null}
                                {el.NavThreetextfive ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkNavThreefive}>{el.NavThreetextfive}</a></li>
                                ) : null}
                                </ul>
                            </div>
                            <div className="col">
                                <ul className="nav footer-nav_four flex-column mb-0">
                                {el.TelTextOne ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkTelOne}>{el.TelTextOne}</a></li>
                                ) : null}
                                {el.TelTextTwo ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkTelTwo}>{el.TelTextTwo}</a></li>
                                ) : null}
                                {el.TelTextThree ? (
                                    <li className="nav-item mb-2 card__footer_nav-item"><a className="nav-link p-0" href={el.linkTelThree}>{el.TelTextThree}</a></li>
                                ) : null}
                                {el.MailText ? (
                                    <li className="nav-item mb-2 card__footer_nav-item card__footer_nav-item--mail"><NavLink className="text-primary fw-semibold p-0" to={el.linkMail}>{el.MailText}</NavLink></li>
                                ) : null}
                                {el.MailTextTwo ? (
                                    <li className="nav-item mb-2 card__footer_nav-item card__footer_nav-item--mail"><NavLink className="text-primary fw-semibold p-0" to={el.linkMailTwo}>{el.MailTextTwo}</NavLink></li>
                                ) : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="fs-sm text-muted footer_copyright">
                        {el.CopyrightText}
                        <NavLink to={el.CopyrightLink} target="_blank" rel="noopener" className="text-dark text-decoration-none opacity-90 footer_copyright__link"> {el.CopyrightLinkText}</NavLink>
                    </div>
                </div>
            ))}
        </div>
    );
}

CardFooter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

//