import React, {useState} from 'react';
import { ReactComponent as ButtontopIcon } from "./assets/topbutton.svg";
import styled from 'styled-components';
import "./Style.scss";

 const Button = styled.div`
   position: fixed;    
   bottom: 25px;  
   left: 91%;
   z-index: 1;
   cursor: pointer;
   opacity: 0.5;

   svg {
    width: 48px;
    height: 48px;
  }

`

const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <Button className="scroll-button">
     <ButtontopIcon onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}} />
    </Button>
  );
}
  
export default ScrollButton;

