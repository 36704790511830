import React from "react";
import PropTypes from "prop-types";
//import "./style.css";
import "./Style.scss";

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} link
 * @param {String} imgicon
 *
 */
export default function CardOurClients({ className, id, link, imgicon }) {
  return (
      <div className={("card__our-clients", className, id)} style={{width: '190px'}}>        
          <a className="card__our-clients_link" href={link}>
              <div className="card__body_our-clients">
                  <div className="image__our-clients">
                      <img src={imgicon} alt="" />                      
                  </div>                  
              </div>              
          </a>
      </div>
  );
}

CardOurClients.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  link: PropTypes.string,
  imgicon: PropTypes.string,
};
