import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import cartdImg from '../../assets/cart.svg';
import brandImg from '../../assets/brand-img.svg';
import './style.css';

export default function Navigation() {
    return (
        <div className="nav-wrap">
            <Container>
                <Navbar expand="lg">
                    <Navbar.Brand className="navbar-brand pe-sm-3" href="/">
                        <span className="text-primary flex-shrink-0 me-2">
                            <img style={{ width: '35px', height: '32px', objectFit: 'cover' }} src={brandImg} alt="" />
                        </span>
                        Around
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: 'fit-content' }}
                            navbarScroll
                        >
                            <NavLink className="link-nav_item" to="/">Home</NavLink>
                            <NavLink className="link-nav_item" to="/about">About</NavLink>
                            <NavDropdown className="link-nav_item" title="Link" id="navbarScrollingDropdown">
                                <NavDropdown.Item className="link-nav_item" href="#action3">Action</NavDropdown.Item>
                                <NavDropdown.Item className="link-nav_item" href="#action4">Another action</NavDropdown.Item>                                
                            </NavDropdown>
                        </Nav>
                        <Nav.Link className="btn btn-barnav btn-sm btn-success fs-sm order-lg-3 d-sm-inline-flex" href="#">
                            <img className="ai-cart fs-xl me-2 ms-n1" style={{ width: '20px', height: '20px', objectFit: 'cover' }} src={cartdImg} alt="" />                            
                            Buy now
                        </Nav.Link>
                    </Navbar.Collapse>                                    
                </Navbar>                
            </Container>
        </div>
    );
}
