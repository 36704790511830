import React from "react";
import PropTypes from "prop-types";
import "./Style.scss";

// temp
import { dataestimation } from "../../assets/_template/data";

/**
 * 
 * @param {String|Array|Object} className
 *
 */
export default function CardEstimation({ className }) {
  return (
      <div className={("card__estimation  mb-md-0", className)}>
        {dataestimation.map((el, i) => (
        <div key={i}>
          <div className="position-relative" style={{ maxWidth: '416px', margin: '0 auto' }}>
            <div className="card__estimation_image-wrap">
              <img className="d-block position-relative zindex-2 card__estimation_image" src={el.image} alt="" />
            </div>
              <div className="bg-success position-absolute start-0 bottom-0 w-100" style={{ height: '85.4%', borderRadius: '2.25rem 8rem 2.25rem 2.25rem' }}></div>
          </div>
          <div className="text-center text-md-start pt-3 mt-3">
              <h2 className="mb-2">{el.title}</h2>
              <p className="fs-xl text-muted mb-2 card__estimation_subtitle">{el.subtitle}</p>
              <div className="position-relative pt-4" style={{ maxWidth: '500px' }}>
                  <div className="position-absolute top-0 start-0 w-100 text-center text-md-start ms-md-1">
                      <img src={el.imageicon} alt="" />
                  </div>
                  <p className="fs-xl position-relative zindex-2 mb-0 card__estimation_text">{el.text} <strong>{el.boldtext}</strong>{el.texts}</p>
              </div>
          </div>
          </div>
        ))}
      </div>
  );
}

CardEstimation.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};
