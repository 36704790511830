export const datasolutionbusinessimage = [  
  {
    solutionsBg: require("../../assets/solutions-1.png"),
    chart: require("../../assets/solutions-2.png"),
    numbers: require("../../assets/solutions-3.png"),
  },
];

  export const datarating = [  
    {
      id: 1,
      content: 'Happy clients',
      number: '540+',
    },
    {
      id: 2,
      content: 'Projects completed',
      number: '1070',
    },
    {
      id: 3,
      content: 'Full time specialists',
      number: '30+',
    },
    {
      id: 4,
      content: 'Awards won',
      number: '15',
    },
  ];
  
  export const dataourserviceslist = [  
    {
      id: 1,
      link: '#',
      imgicon: require("../../assets/services-img-1.svg").default,
      title: 'Advertising communications',
      text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint velit officia consequat duis enim velit mollit.',
      imgarrow: require("../../assets/services-arrow.svg").default,
    },
    {
      id: 2,
      link: '#',
      imgicon: require("../../assets/services-img-2.svg").default,
      title: 'Marketing and branding',
      text: 'Find aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur neque congue.',
      imgarrow: require("../../assets/services-arrow.svg").default,
    },
    {
      id: 3,
      link: '#',
      imgicon: require("../../assets/services-img-3.svg").default,
      title: 'Web and Internet marketing',
      text: 'Hac erat leo proin odio est sed sit felis facilisi integer sed congue neque turpis dictumst sit sed volutpat aliquet tortor non.dictumst sit sed volutpat aliquet tortor non',
      imgarrow: require("../../assets/services-arrow.svg").default,
    },
    {
      id: 4,
      link: '#',
      imgicon: require("../../assets/services-img-1.svg").default,
      title: 'Email marketing',
      text: 'Adipiscing posuere dui, amet, augue nisl dictum justo, enim. Sed neque congue non quam ultrices interdum vitae vestibulum.',
      imgarrow: require("../../assets/services-arrow.svg").default,
    },
    {
      id: 5,
      link: '#',
      imgicon: require("../../assets/services-img-2.svg").default,
      title: 'Working on communication',
      text: 'Molestie enim tempus egestas a at enim. Velit hendrerit nibh eget porta pretium. Ipsum orci habitasse eget malesuada platea.',
      imgarrow: require("../../assets/services-arrow.svg").default,
    },
    {
      id: 6,
      link: '#',
      imgicon: require("../../assets/services-img-3.svg").default,
      title: 'Business copywriting',
      text: 'Massa donec quam orci feugiat. Quis iaculis facilisi ornare aenean lorem enim purus in lacus in ullamcorper diam consequat.',
      imgarrow: require("../../assets/services-arrow.svg").default,
    },
  ];

  export const dataindistriesslider = [  
    {
      id: 1,
      image: require("../../assets/industries-img-1.jpg"),
      title: 'Services',
      text: 'Id habitasse dui habitant',
      listtext: 'Rutrum tempor sit tincidunt',
      textlist: 'Imperdiet est quis enim facilisis',
    },
    {
      id: 2,
      image: require("../../assets/industries-img-2.jpg"),
      title: 'Retail',
      text: 'Ultrices volutpat sit mattis',
      listtext: 'Egestas neque arcu duis',
      textlist: 'Metus magna viverra blandit',
    },
    {
      id: 3,
      image: require("../../assets/industries-img-3.jpg"),
      title: 'Technology',
      text: 'Diam diam nunc monte',
      listtext: 'Nulla egestas arcu proin sit',
      textlist: 'Nunc sapien turpis vulputate',
    },    
  ];

  export const datacardourclients = [  
    {
      id: 1,
      imgicon: require("../../assets/services-img-1.svg").default,
      link: '#',
    },
    {
      id: 2,
      imgicon: require("../../assets/services-img-2.svg").default,
      link: '#',
    },
    {
      id: 3,
      imgicon: require("../../assets/services-img-3.svg").default,
      link: '#',
    },
    {
      id: 4,
      imgicon: require("../../assets/services-img-2.svg").default,
      link: '#',
    },
    {
      id: 5,
      imgicon: require("../../assets/services-img-1.svg").default,
      link: '#',
    },
    {
      id: 6,
      imgicon: require("../../assets/services-img-3.svg").default,
      link: '#',
    },
    {
      id: 7,
      imgicon: require("../../assets/services-img-2.svg").default,
      link: '#',
    },
    {
      id: 8,
      imgicon: require("../../assets/services-img-1.svg").default,
      link: '#',
    },
    {
      id: 9,
      imgicon: require("../../assets/services-img-3.svg").default,
      link: '#',
    },
    {
      id: 10,
      imgicon: require("../../assets/services-img-2.svg").default,
      link: '#',
    },
    {
      id: 11,
      imgicon: require("../../assets/services-img-1.svg").default,
      link: '#',
    },
    {
      id: 12,
      imgicon: require("../../assets/services-img-2.svg").default,
      link: '#',
    },
    {
      id: 13,
      imgicon: require("../../assets/services-img-3.svg").default,
      link: '#',
    },
    {
      id: 14,
      imgicon: require("../../assets/services-img-2.svg").default,
      link: '#',
    },
    {
      id: 15,
      imgicon: require("../../assets/services-img-1.svg").default,
      link: '#',
    },
  ];

  export const dataestimation = [  
    {
      image: require("../../assets/est-img.png"),
      title: 'Nicolas Black',
      subtitle: 'CEO Around Group',
      imageicon: require("../../assets/est-img-icon.svg").default,
      text: 'The main task of our organization is',
      boldtext: 'to assess the state of your enterprise now and how you can achieve your goals. ',
      texts: 'Self-assessment of current activities and review of processes formulate decisions to achieve the desired outcome.',
    },
  ];

  export const dataestimationlist = [  
    {
      id: 1,
      title: 'Formulation of the problem',
      text: 'Find aute irure dolor in reprehenderi voluptate velit esse cillum dolore eu fugiat nulla pariatur am luctus.',
    },
    {
      id: 2,
      title: 'Assessment of the current state',
      text: 'Risus quam quis at euismod vitae dui elementu eu in diam malesuada mattis ut urna integer erat nisi nibh.',
    },
    {
      id: 3,
      title: 'Business plan creation',
      text: 'A sagittis morbi massa venenatis, egestas viverra ac elit nibh tellus nisi in nec tellus mauris feugiat gravida.',
    },
    {
      id: 4,
      title: 'Strategy implementation',
      text: 'Elementum purus, nisl ipsum sit. Amet sit praesent proin sit cras. Leo sed praesent nunc vel nec risus.',
    },
  ];

  export const datatestimonialsslider = [  
    {
      id: 1,
      image: require("../../assets/testimonials-1.jpg"),
      name: 'Fannie Summers',
      post: 'VP of Sales',
      desc: 'In et dui amet amet elementum urna, dictum. Eget leo ornare bibendum a fermentum. Rutrum pellentesque ipsum cras purus ac a ullamcorper. Elit ac amet quam tellus euismod ac aliquam pharetra lectus.',
      imagetwo: require("../../assets/testimonials-2.jpg"),
      nametwo: 'Brooklyn Simmons',
      posttwo: 'Marketing Coordinator',
      desctwo: 'In quisque dolor, aliquam faucibus. Gravida diam ornare egestas proin quis odio suspendisse pellentesque. Condimentum risus, rutrum curabitur faucibus mi a turpis morbi. Nulla euismod dolor quis amet sed.',
    },
    {
      id: 2,
      image: require("../../assets/testimonials-3.jpg"),
      name: 'Wadw warren',
      post: 'President of Sales',
      desc: 'Vitae tempor morbi tellus pulvinar. Ut iaculis sit tristique in turpis volutpat quam nec. Pretium eu nulla egestas ultrices. Donec in pulvinar ut fermentum a, nunc, aliquam. Integer dui dui ornare sed lacinia.',
      imagetwo: require("../../assets/testimonials-4.jpg"),
      nametwo: 'leslie Alexander',
      posttwo: 'CEO. Divi',
      desctwo: 'Malesuada at ullamcorper adipiscing lobortis vestibulum. Aliquet lobortis justo arcu ut auctor porttitor amet, tortor. Justo.',
    },
    {
      id: 3,
      image: require("../../assets/testimonials-5.jpg"),
      name: 'Robert Fox',
      post: 'Marketing Coordinator',
      desc: 'At felis non consequat neque in ultrices tortor purus dui. At sed fermentum, egestas amet et donec euismod risus. Nulla mauris.',
      imagetwo: require("../../assets/testimonials-6.jpg"),
      nametwo: 'Cameron Williamson',
      posttwo: 'Marketing Coordinator',
      desctwo: 'Nam convallis maecenas leo sapien interdum id. Facilisi dictum lacinia in phasellus. Nullam id sed tempor, volutpat blandit urna sagittis, commodo vestibulum. Adipiscing dictumst nunc enim massa lacus lobortis.',
    },
    {
      id: 4,
      image: require("../../assets/testimonials-7.jpg"),
      name: 'Jane Cooper',
      post: 'Medical Assistant',
      desc: 'Sit risus metus, vel neque eu lectus duis. Vulputate facilisi at feugiat mi aenean nunc enim faucibus arcu. Diam id accumsan sit.',
      imagetwo: require("../../assets/testimonials-2.jpg"),
      nametwo: 'Brooklyn Simmons',
      posttwo: 'Marketing Coordinator',
      desctwo: 'In quisque dolor, aliquam faucibus. Gravida diam ornare egestas proin quis odio suspendisse pellentesque. Condimentum risus, rutrum curabitur faucibus mi a turpis morbi. Nulla euismod dolor quis amet sed.',
    },
  ];

  export const datarecentstudies = [  
    {
      id: 1,
      cardbg: '#448c74',
      cardcolortext: '#ffffff',
      title: 'Robotics &amp; artificial intelligence',
      text: 'Cursus volutpat pharetra vel in. Felis ut nulla dui nec, vulputate viverra sit eget vel. Convallis diam nunc, vitae, orci, nibh quam tristique pulvinar. In semper etiam aliquam molestie arcu. Nullam id sed tempor, volutpat blandit urna sagittis, commodo vestibulum. Adipiscing dictumst nunc enim massa.',
      link: '#',
      linktext: 'Read case study',
      image: require("../../assets/recent-img-1.png"),
    },
    {
      id: 2,
      cardbg: '#d1e6df',
      cardcolortext: 'grey',
      title: 'Toothbrush from natural eco materials',
      text: 'Cursus volutpat pharetra vel in. Felis ut nulla dui nec, vulputate viverra sit eget vel. Convallis diam nunc, vitae, orci, nibh quam tristique pulvinar. In semper etiam aliquam molestie arcu. Nullam id sed tempor, volutpat blandit urna sagittis, commodo vestibulum. Adipiscing dictumst nunc enim massa.',
      link: '#',
      linktext: 'Read case study',
      image: require("../../assets/recent-img-2.png"),
    },
    {
      id: 3,
      cardbg: '#9ed7c4',
      cardcolortext: '#448c74',
      title: 'Toothbrush from natural eco materials',
      text: 'Cursus volutpat pharetra vel in. Felis ut nulla dui nec, vulputate viverra sit eget vel. Convallis diam nunc, vitae, orci, nibh quam tristique pulvinar. In semper etiam aliquam molestie arcu. Nullam id sed tempor, volutpat blandit urna sagittis, commodo vestibulum. Adipiscing dictumst nunc enim massa.',
      link: '#',
      linktext: 'Read case study',
      image: require("../../assets/recent-img-2.png"),
    },
    {
      id: 4,
      cardbg: '#19b2d1',
      cardcolortext: '#ceaee8',
      title: 'Robotics &amp; artificial intelligence',
      text: 'Cursus volutpat pharetra vel in. Felis ut nulla dui nec, vulputate viverra sit eget vel. Convallis diam nunc, vitae, orci, nibh quam tristique pulvinar. In semper etiam aliquam molestie arcu. Nullam id sed tempor, volutpat blandit urna sagittis, commodo vestibulum. Adipiscing dictumst nunc enim massa.',
      link: '#',
      linktext: 'Read case study',
      image: require("../../assets/recent-img-4.png"),
    },
  ];

  export const datareadybusiness = [  
    {
      title: 'Ready to take your business to the next level?',
      text: 'Massa velitienes semper faucibus tristique id nibh elementum, id eu aliquamd diam mi tempus at laciniarty scelerisques augue at morbi. Arcu sit orcirs, risus mattissit laoreet.',
    },
  ];

  export const datafooter = [  
    {
      baranddesc: 'Around',
      brandtext: 'The agency with extensive experience that creates marketing strategies for B2B and B2C companies.',
      brandImg: require("../../assets/brand-img.svg").default,
      linkBrand: '/',
      socialIconOne: require("../../assets/social-one.svg").default,
      linksocialIconOne: 'https://api.whatsapp.com/send?phone=79999999999&text=Добрый%20день!%20Я%20хочу%20купить%20сервис',
      socialIconTwo: require("../../assets/social-two.svg").default,
      linksocialIconTwo: 'https://telegram.me/+7(999)9999999',
      socialIconThree: require("../../assets/social-three.svg").default,
      linksocialIconThree: 'skype:username',

      NavOnetextone: 'Services',
      linkNavOneone: '#',
      NavOnetexttwo: 'Industries',
      linkNavOnetwo: '#',
      NavOnetextthree: 'Clients',
      linkNavOnethree: '#',
      NavOnetextfour: 'Reviews',
      linkNavOnefour: '#',
      NavOnetextfive: 'Casestudies',
      linkNavOnefive: '#',

      NavTwotextone: 'Contacts',
      linkNavTwoone: '#',
      NavTwotexttwo: 'Help center',
      linkNavTwotwo: '#',
      NavTwotextthree: 'Privacy policy',
      linkNavTwothree: '#',
      NavTwotextfour: 'Help center',
      linkNavTwofour: '#',
      NavTwotextfive: 'Privacy policy',
      linkNavTwofive: '#',

      NavThreetextone: 'About',
      linkNavThreeone: '/About',
      NavThreetexttwo: 'Careers',
      linkNavThreetwo: '#',
      NavThreetextthree: 'News & insights',
      linkNavThreethree: '#',
      NavThreetextfour: 'Industry forecasts',
      linkNavThreefour: '#',
      NavThreetextfive: 'Industry forecasts',
      linkNavThreefive: '#',

      TelTextOne: '+ 1 526 220 0444',
      linkTelOne: 'tel:+15262200444',
      TelTextTwo: '+ 1 526 220 0000',
      linkTelTwo: 'tel:+15262200000',
      TelTextThree: '+ 7 999 999 9999',
      linkTelThree: 'tel:+79187446870',
      MailText: 'example@gmail.com',
      linkMail: 'mailto:mmichailov83@mail.ru',
      MailTextTwo: 'examplesexample@gmail.com',
      linkMailTwo: 'mailto:examplesexample@gmail.com',
      
      CopyrightText: '© All rights reserved. Made by',
      CopyrightLinkText: 'Createx Studio',
      CopyrightLink: 'mailto:CreatexStudioe@gmail.com',
    },
  ];
                                         