import React from "react";
import PropTypes from "prop-types";
import { MouseParallaxChild } from "react-parallax-mouse";
import "./style.css";

// temp
import { datasolutionbusinessimage } from "../../assets/_template/data";

/**
 * 
 * @param {String|Array|Object} className
 *
 */

export default function CardSolutionsBusinessImage({ className }) {
  return (
    <div className="parallax me-lg-n4 me-xl-n5"
      style={{ maxWidth: '600px', position: 'relative' }}>

      {datasolutionbusinessimage.map((el) => (
        <div className={("card__solution-business_image", className)} style={{ height: "100%", width: "100%" }}> {/*, overflow: "hidden"*/}
          <MouseParallaxChild
            factorX={0.1}
            factorY={0.1}
            style={{ width: "auto", height: "100%" }}
          >

            <MouseParallaxChild
              factorX={0.1}
              factorY={0.1}
              style={{ width: "auto", height: "100%" }}
            >
              <div className="parallax-layer-one"
                style={{ maxWidth: '600px', width: '100%', height: '100%', position: 'relative', right: '0px', top: '0px' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={el.solutionsBg} alt="" />
              </div>
            </MouseParallaxChild>

            <div className="parallax-layer-two"
              style={{ maxWidth: '600px', width: '100%', position: 'absolute', display: 'block', top: '0' }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={el.chart} alt="" />
            </div>
            <div className="parallax-layer-three"
              style={{ maxWidth: '600px', width: '100%', position: 'absolute', display: 'block', top: '0', right: '0' }}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={el.numbers} alt="" />
            </div>

          </MouseParallaxChild>
        </div>
      ))}

    </div>
  );
}

CardSolutionsBusinessImage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};
