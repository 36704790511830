import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { motion } from 'framer-motion';
import './Style.scss';
import Navigation from "../../Components/Navigation";
import CardFormsHeader from "../../Components/CardFormsHeader";
import CardRating from "../../Components/CardRating";
import { Container, Row } from "react-bootstrap";
import CardSolutionsBusinessImage from "../../Components/CardSolutionsBusinessImage";
import { MouseParallaxContainer } from "react-parallax-mouse";
import CardOurServices from "../../Components/CardOurServices";
import CardIndustriesSlider from "../../Components/CardIndustriesSlider";
import CardOurClients from "../../Components/CardOurClients";
import CardEstimation from "../../Components/CardEstimation";
import CardEstimationList from "../../Components/CardEstimationList";
import CardTestimonialsSlider from "../../Components/CardTestimonialsSlider";
import CardRecentStudies from "../../Components/CardRecentStudies";
import CardReadyBusiness from "../../Components/CardReadyBusiness";
import { ReactComponent as ReadySvg } from '../../assets/ready-img.svg';
import CardFooter from "../../Components/CardFooter";
import Slider from "react-slick";
import Marquee from "react-fast-marquee";
import { NavLink } from "react-router-dom";

// temp
import { dataourserviceslist, dataindistriesslider, datacardourclients, dataestimationlist, datatestimonialsslider, datarecentstudies } from "../../assets/_template/data";

//hooks
import useCardIndustriesSlider from "../../hooks/useCardIndustriesSlider";
import useCardTestimonialsSlider from "../../hooks/useCardTestimonialsSlider";

//scroll-animate
const textAnimation = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: custom => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.8 },
    }),
  }

export default function Home() { 

    //sliders
    const {
        sliderRef: industriesSliderRef,
        settings: industriesSliderSettings,
      } = useCardIndustriesSlider(dataindistriesslider);
    
    const {
       sliderRef: testimonialsSliderRef,
       settings: testimonialsSliderSettings,
    } = useCardTestimonialsSlider(datatestimonialsslider);  

    //show more
    const [hoveringEvent, setHoveringEvent] = useState(undefined);

  const handleMouseOverEvent = (event) => {
    setHoveringEvent(event);
  };

  const handleMouseOutEvent = () => {
    setHoveringEvent(undefined);
  };
  useEffect(() => {
    if (datarecentstudies.length > 0) {
      handleMouseOverEvent(datarecentstudies[0]);
    }
  }, [datarecentstudies]);
  //
  const [showCountCardsLimit, setShowCountCardsLimit] = useState(2);

  const onLoadNext = () => {
    setShowCountCardsLimit(showCountCardsLimit + 2);
  };
    
    return (
        <main className="main">
            <Navigation />
            <MouseParallaxContainer className="parallax"
                containerStyle={{ width: "100%" }}
                globalFactorX={0.05}
                globalFactorY={0.05}
                resetOnLeave>
                <div>
                    <Container className="pt-2 pt-sm-4 pb-sm-2 pb-md-4 py-xl-5">
                        <Row className="align-items-center py-5 my-xxl-5 section-one">
                            <div className="col-lg-7 order-lg-2 d-flex justify-content-center justify-content-lg-end mb-4 mb-md-5 mb-lg-0 pb-3 pb-md-0">
                                <CardSolutionsBusinessImage />
                            </div>
                            <div className="col-lg-5 order-lg-1">
                                <h1 className="display-3 text-center text-lg-start pb-sm-2 pb-md-3">The effective solutions for your business</h1>
                                <p className="fs-lg text-center text-lg-start pb-xl-2 mx-auto mx-lg-0 mb-5" style={{ maxWidth: '420px', fontSize: '1rem', color: '#576071' }}>
                                    We are a team who creates marketing strategies for B2B and B2C companies.
                                </p>
                                <CardFormsHeader />
                            </div>
                        </Row>
                    </Container>
                    <Container className="pt-2 pt-sm-4 pb-sm-2 pb-md-4 py-xl-5">
                        <Row className="rating-bg rounded-5 py-4 py-md-5 px-lg-5 section-two">
                            <CardRating />
                        </Row>
                    </Container>
                    <Container className="py-5">
                        <h2 className="h1 text-center">Our services</h2>
                        <p className="text-center pb-4 mb-2 mb-lg-3">We provide a wide range of consulting services</p>                        
                        <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">

                            {dataourserviceslist.map((el, i) => (
                                <div
                                    className="col our-services__list_item"
                                    key={i}
                                >
                                    <CardOurServices
                                        link={el.link}
                                        imgicon={el.imgicon}
                                        title={el.title}
                                        text={el.text}
                                        imgarrow={el.imgarrow}
                                    />
                                </div>
                            ))}

                        </Row>
                    </Container>
                    <Container className="pb-5 pt-3 pt-sm-4 pt-lg-5 my-xl-3 my-xxl-5">
                        <h2 className="h1 text-center">Industries we work</h2>                           
                        <p className="text-center pb-4 mb-2 mb-lg-3">We have been helping businesses in the following industries for over a decade</p>                     
                        <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">

                            <Slider
                                {...industriesSliderSettings}
                                ref={industriesSliderRef}
                                className="col industries-slider__wrap"
                            >
                                {dataindistriesslider.map((el, i) => (
                                    <div
                                        className="industries-slider__slide"
                                        key={i}
                                    >
                                        <CardIndustriesSlider
                                            image={el.image}
                                            title={el.title}
                                            text={el.text}
                                            textlist={el.textlist}
                                            listtext={el.listtext}
                                        />
                                    </div>
                                ))}
                            </Slider>

                        </Row>
                        <div className="text-center industri_btn__wrap pt-5 mt-2 pt-lg-5">
                            <NavLink className="btn industri_btn btn-success" to="/about">See all industries</NavLink>
                        </div>
                    </Container>
                    <div className="pb-5 pt-2 pt-sm-3 pt-md-4 pt-lg-5 my-xl-3 my-xxl-5">
                        <Container className="pb-4 mb-2 mb-lg-3">
                            <h2 class="h1 text-center">Our clients</h2>
                            <p class="text-center mb-0">More than 2,000 clients using Around to analyze data</p>
                        </Container>
                        <div className="pb-4">
                            <Marquee className="section-marquee">
                                {datacardourclients.map((el, i) => (
                                    <div
                                        className="section-marquee__list"
                                        style={{width: '190px', marginRight: '24px'}}
                                        key={i}
                                    >
                                        <CardOurClients 
                                        imgicon={el.imgicon}
                                        link={el.link}
                                        />
                                    </div>
                                ))}
                            </Marquee>
                        </div>
                        <div className="pb-4">
                            <Marquee className="section-marquee" direction={"right"}>
                                {datacardourclients.map((el, i) => (
                                    <div
                                        className="section-marquee__list"
                                        style={{ width: '190px', marginRight: '24px' }}
                                        key={i}
                                    >
                                        <CardOurClients
                                            imgicon={el.imgicon}
                                            link={el.link}
                                        />
                                    </div>
                                ))}
                            </Marquee>
                        </div>
                    </div>
                    <Container className="pb-5 pt-3 pt-md-4 pt-lg-5 my-xl-3 my-xxl-5">
                        <Row className="pb-md-3 pb-lg-4 mb-2 mb-lg-3">
                            <div class="col-md-5 mb-4 mb-md-0">                                
                                <CardEstimation />                               
                            </div>
                            <div className="col-md-7 col-lg-6 col-xxl-5 offset-lg-1 offset-xxl-2">
                                <div className="ps-md-4 ps-lg-0">
                                    <ol className="estimstion-list">
                                        {dataestimationlist.map((el, i) => (
                                            <motion.li
                                                initial="hidden"
                                                whileInView="visible"
                                                variants={textAnimation}
                                                custom={1}
                                                className="estimstion-list__item my-3" key={i}>
                                                <CardEstimationList
                                                    title={el.title}
                                                    text={el.text}
                                                />
                                            </motion.li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        </Row>
                    </Container>
                    <Container className="mt-n3 mt-sm-n2 pb-5 mb-md-2 mb-lg-3 mb-xl-4 mb-xxl-5">
                        <h2 className="h1 text-center pb-3 pb-lg-4">Testimonials</h2>                        
                        <Row>    

                            <Slider
                                {...testimonialsSliderSettings}
                                ref={testimonialsSliderRef}
                                className="col testimonials-slider__wrap"
                            >

                                {datatestimonialsslider.map((el, i) => (
                                    <div
                                        className="testimonials-slider__slide"
                                        key={i}
                                    >
                                        <CardTestimonialsSlider
                                            image={el.image}
                                            name={el.name}
                                            post={el.post}
                                            desc={el.desc}
                                            imagetwo={el.imagetwo}
                                            nametwo={el.nametwo}
                                            posttwo={el.posttwo}
                                            desctwo={el.desctwo}
                                        />
                                    </div>
                                ))}

                            </Slider>

                        </Row>
                    </Container>
                    <Container className="container pt-5 mt-2 pt-sm-4 pb-5 mb-lg-3 mb-xl-4 mb-xxl-5">
                        <h2 className="h1 text-center mt-2 pb-3 pb-lg-4">Recent case studies</h2>
                        <Row className="row-cols-1 row-cols-md-2 g-4">
                            {datarecentstudies
                            .slice(0, showCountCardsLimit)
                            .map((el, i) => (
                                <div
                                    className="recent-studies__item"
                                    key={i}
                                >
                                    <CardRecentStudies 
                                        cardbg={el.cardbg}
                                        cardcolortext={el.cardcolortext}
                                        title={el.title}                                        
                                        text={el.text}                                        
                                        link={el.link}
                                        linktext={el.linktext}
                                        image={el.image}
                                    />
                                </div>
                            ))}
                        </Row>
                        <div className="text-center my-2 mt-sm-3 mt-lg-0 pt-4 pb-1 pb-sm-3 pb-md-4 pt-lg-5">
                            {showCountCardsLimit && (
                                <button className="btn btn-outline-success" style={{ padding: '0.82rem', borderRadius: '1rem' }} onClick={onLoadNext}>
                                    Read all case studies
                                </button>
                            )}
                        </div>
                    </Container>
                    <div className="bg-success dark-mode py-5">
                        <Container className="container pt-lg-2 pt-xl-4 pt-xxl-5 pb-1 pb-sm-3">
                            <CardReadyBusiness/>
                            <div className="d-none d-md-block text-center mt-n5">
                                <ReadySvg/>
                            </div>
                        </Container>
                    </div>
                    <footer className="footer pt-lg-5 pt-4">
                        <Container className="container pt-3 mt-md-2 mt-lg-3">
                           <CardFooter/>
                        </Container>
                    </footer>
                </div>
            </MouseParallaxContainer>
        </main>
    );
  }
                    