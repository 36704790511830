import React from "react";
import PropTypes from "prop-types";
import "./Style.scss";

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} cardbg
 * @param {String} cardcolortext
 * @param {String} title
 * @param {String} text 
 * @param {String} link
 * @param {String} linktext
 * @param {String} image
 *
 */
export default function CardRecentStudies({ className, id, cardbg, title, cardcolortext, text, link, linktext, image }) {
    return (
        <div className={("card__recent-studies", className, id)}>
            <div className="col card__recent-studies_wrap">
            
            <div className="card card__recent-studies_body border-0 h-100 overflow-hidden pt-3 pt-xl-4 px-lg-3 px-xl-4" style={{ backgroundColor: `${cardbg}` }}>
              <div className="card-body position-relative zindex-2 pb-0" style={{ color: `${cardcolortext}` }}>
                <h3 className="h4 card-title" style={{marginBottom: '1rem'}}>{title}</h3>
                <p className="card-text opacity-80 pb-sm3 pb-md-4 mb-2">{text}</p>
                <a className="card__recent-studies_link px-0" href={link}>{linktext}</a>
              </div>
              <div className="d-flex justify-content-end mt-sm-n5 me-n4 card__recent-studies_image--wrap">
                <img className="card__recent-studies_image" src={image} alt=""/>
              </div>
            </div>          

            </div>
        </div>
    );
}

CardRecentStudies.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  cardbg: PropTypes.string,
  cardcolortext: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linktext: PropTypes.string,
  image: PropTypes.string,
};
