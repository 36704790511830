import React from "react";
import PropTypes from "prop-types";
//import "./style.css";
import "./Style.scss";

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} listtext
 * @param {String} image
 * @param {String} title
 * @param {String} text
 * @param {String} textlist
 *
 */
export default function CardIndustriesSlider({ className, id, listtext, image, title, text, textlist }) {
  return (
      <div className={("card__industries-slider", className, id)}>        
          <div className="card card__industries-slider_wrap border-0 h-100">
                <div className="card-img-top card__industries-slider_image-wrap overflow-hidden">
                    <img className="rounded-5 rounded-end-0 card__industries-slider_image" src={image} alt=""/>
                </div>
                <div className="card-body card__industries-slider__body">
                  <h3 className="card__industries-slider__body--title">{title}</h3>
                  <ul className="ps-4 mb-n2">
                    <li className="mb-2">{text}</li>
                    <li className="mb-2">{listtext}</li>
                    <li className="mb-2">{textlist}</li>
                  </ul>
                </div>
              </div>
      </div>
  );
}

CardIndustriesSlider.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  listtext: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  textlist: PropTypes.string,
};

