import React from "react";
import PropTypes from "prop-types";
import "./Style.scss";

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id 
 * @param {String} title
 * @param {String} text 
 *
 */
export default function CardEstimationList({ className, id, title, text, }) {
  return (
    <div
      className={("d-flex justify-content-end", className, id)}>

      <div className="card border-0 ps-3" style={{ width: 'calc(100% - 1.75rem)', borderRadius: '30px' }}>
        <div className="card-body ps-4" style={{ padding: '2.37rem' }}>
          <h3 className="h5 card-title pb-2 mb-1" style={{ fontWeight: '700' }}>{title}</h3>
          <p className="card-text">{text}</p>
        </div>
      </div>

    </div>
  );
}

CardEstimationList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  title: PropTypes.string,
  text: PropTypes.string,
};
