import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from 'react-custom-scrollbars';
import "./Style.scss";

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} image
 * @param {String} name
 * @param {String} post
 * @param {String} desc
 * @param {String} imagetwo
 * @param {String} nametwo
 * @param {String} posttwo
 * @param {String} desctwo
 *
 */
export default function CardTestimonialsSlider({ className, id, image, name, post, desc, imagetwo, nametwo, posttwo, desctwo }) {
    return (
        <div className={("card__testimonials-slider", className, id)}>
            <div className="card__testimonials-slider_one--wrap">

                <div className="card__testimonials-slider_one border-0 mb-4">
                    <div className="card__testimonials-slider_body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="card__testimonials-slider_image--wrap">
                                <img className="card__testimonials-slider_image rounded-circle" src={image} width="60" alt="" />
                            </div>
                            <div className="ps-3">
                                <div className="card__testimonials-slider_name h6 mb-1">{name}</div>
                                <div className="card__testimonials-slider_post fs-sm text-muted">{post}</div>
                            </div>
                        </div>
                        <div className="card__testimonials-slider_desc--wrap">
                            <Scrollbars style={{ height: 80, right: 0 }}>
                                <p className="card__testimonials-slider_desc">{desc}</p>
                            </Scrollbars>
                        </div>
                    </div>
                </div>

                <div className="card__testimonials-slider_two border-0">
                    <div className="card__testimonials-slider_body">
                        <div className="d-flex align-items-center mb-3">
                            <div className="card__testimonials-slider_image--wrap">
                                <img className="card__testimonials-slider_image rounded-circle" src={imagetwo} width="60" alt="" />
                            </div>
                            <div className="ps-3">
                                <div className="card__testimonials-slider_name h6 mb-1">{nametwo}</div>
                                <div className="card__testimonials-slider_post fs-sm text-muted">{posttwo}</div>
                            </div>
                        </div>
                        <div className="card__testimonials-slider_desc--wrap">
                            <Scrollbars style={{ height: 80, right: 0 }}>
                                <p className="card__testimonials-slider_desc">{desctwo}</p>
                            </Scrollbars>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

CardTestimonialsSlider.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  image: PropTypes.string,
  name: PropTypes.string,
  post: PropTypes.string,
  desc: PropTypes.string,
  imagetwo: PropTypes.string,
  nametwo: PropTypes.string,
  posttwo: PropTypes.string,
  desctwo: PropTypes.string,
};

