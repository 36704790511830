import React from "react";
import { Formik, Field, Form } from "formik";
import "./Style.scss";

export default function CardFormsFuter() {
  return (
    <div className="Forms">
      <Formik
        initialValues={{ name: "@", email: "" }}
        onSubmit={async (values) => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form className="forms__futer_form mx-auto mx-lg-0"> 
          <Field className="inputs-name__futer_form" style={{maxWidth: '40px', border: 'none'}} name="name" type="text" />
          <Field className="inputs-mail__futer_form" style={{background: 'transparent', outline: 'none'}} name="email" type="email" placeholder="Enter your email"/>
          <button className="buttons-futer_form btn btn-warning" type="submit">Book a call</button>
        </Form>
      </Formik>
    </div>
  );
}

//